<template>
    <div class="home-slider">
        <div class="home-slider__content-wrapper">
            <h2 class="home-slider__title heading heading--md">{{ title }}</h2>
            <div class="home-slider__buttons">
                <ViewAllButton
                    :path="path"
                    :query="query"
                    @show-all="onShowAll"
                />
                <div
                    slot="button-prev"
                    class="swiper-button-prev"
                    :class="className + '__prev'"
                >
                    <ArrowSliderIcon height="35" />
                </div>
                <div
                    slot="button-next"
                    class="swiper-button-next"
                    :class="className + '__next'"
                >
                    <ArrowSliderIcon height="35" />
                </div>
            </div>
        </div>
        <swiper
            ref="homeSwiper"
            class="swiper-container"
            :options="swiperOption"
            :auto-destroy="false"
        >
            <swiper-slide
                v-for="(slide, index) in slides"
                ref="slide"
                :key="`slide_${index}`"
            >
                <asset-card :key="slide.id" :asset="slide"></asset-card>
            </swiper-slide>
        </swiper>
        <div
            slot="pagination"
            :class="'swiper-pagination-' + className"
            class="swiper-pagination-bullets"
        ></div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import AssetCard from '@/components/assetsTable/AssetCard.vue';
import ViewAllButton from '@/components/common/ViewAllButton.vue';

//SVG
import ArrowSliderIcon from 'Sprites/netbutton_feat.svg?inline';

export default {
    components: {
        Swiper,
        SwiperSlide,
        AssetCard,
        ViewAllButton,
        ArrowSliderIcon,
    },
    props: {
        slides: { type: Array, default: () => [] },
        title: { type: String, default: '' },
        path: { type: String, default: '' },
        query: { type: Object, default: () => {} },
        className: { type: String, default: null },
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 5,
                spaceBetween: 21,
                autoHeight: true,
                navigation: {
                    prevEl: `.${this.className}__prev`,
                    nextEl: `.${this.className}__next`,
                },
                pagination: {
                    el: `.swiper-pagination-${this.className}`,
                    clickable: true,
                    renderBullet(index, className) {
                        return `<span class="${className} swiper-pagination-bullet-custom"></span>`;
                    },
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 12,
                    },
                    450: {
                        slidesPerView: 2,
                        spaceBetween: 12,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 21,
                    },
                    1000: {
                        slidesPerView: 4,
                        spaceBetween: 21,
                    },
                    1200: {
                        slidesPerView: 5,
                        spaceBetween: 21,
                    },
                },
            },
        };
    },
    computed: {
        swiper() {
            return this.$refs.homeSwiper.$swiper;
        },
    },
    beforeDestroy() {
        this.swiper.destroy(true, false);
    },
    methods: {
        onShowAll() {
            this.$emit('show-all', event);
        },
    },
};
</script>

<style lang="scss" scoped>
.home-slider {
    .home-slider__content-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .home-slider__buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .swiper-button-prev,
            .swiper-button-next {
                cursor: pointer;
                position: relative;
                z-index: 1;
                left: 0; //reset default swiper position
                right: 0; //reset default swiper position
                outline: 0;
                svg {
                    fill: $blue-main;
                }
                &:after {
                    display: none; //hide default swiper arrows
                }

                &:hover,
                &:focus {
                    svg {
                        fill: lighten(#fa9f2a, 15%);
                    }
                }

                @media (max-width: $xs-up) {
                    visibility: hidden;
                    display: none;
                }
            }
            .swiper-button-prev {
                margin-left: 12px;
                svg {
                    transform: rotate(180deg);
                }
            }
            .swiper-button-next {
                margin-left: 4px;
            }
        }
    }
    .swiper-container {
        width: 100%;
    }

    .swiper-pagination-bullets {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: $xs-up) {
            visibility: hidden;
            display: none;
        }

        /deep/ .swiper-pagination-bullet-custom {
            $size: 10px;
            width: $size !important;
            height: $size !important;
            line-height: $size !important;
            text-align: center;
            opacity: 1;
            background: #dce3e9;
            transition: all $transition-values;
            margin-left: 6px;
            outline: 0;

            &:hover {
                opacity: 1;
            }

            &.swiper-pagination-bullet-active {
                opacity: 1;
                background: #556f8b;
            }
        }
    }
}
</style>
