<template>
    <router-link
        :to="{
            name: HOME_PAGE_NAME_GRID,
            params: { name: path },
            query: query,
        }"
    >
        <button class="button__view-all" @click="onClick">
            <div class="button__text">View All</div>
            <ViewAllArrowIcon class="button__icon" />
        </button>
    </router-link>
</template>

<script>
import ViewAllArrowIcon from 'Sprites/view_all_arrow.svg?inline';
import { HOME_PAGE_NAME_GRID } from '@/router/pageNameConstants.js';

import EventBus from '@/EventBus';

export default {
    name: 'ViewAllButton',
    components: { ViewAllArrowIcon },
    props: {
        path: { type: String, default: '' },
        query: { type: Object, default: () => {} },
    },
    data() {
        return { HOME_PAGE_NAME_GRID };
    },
    methods: {
        onClick() {
            EventBus.$emit('show-all', event);
        },
    },
};
</script>

<style scoped lang="scss">
.button__view-all {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.6rem 1rem;
    background-color: $blue-main;
    border: 0;
    outline: 0;
    border-radius: 6px;
    text-transform: capitalize;
    font-family: $istok-font;

    .button__text {
        color: white;
    }
    .button__icon {
        fill: white;
        width: 11px;
        height: 11px;
        margin-left: rem(5);
        transition: $transition-values;
    }

    &:hover {
        .button__icon {
            animation: move 0.8s linear infinite;
        }
        @keyframes move {
            0% {
                transform: translateX(0px);
            }
            50% {
                transform: translateX(5px);
            }
            100% {
                transform: translateX(0px);
            }
        }
    }
}
</style>
