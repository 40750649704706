<template>
    <div>
        <HomeSlider
            v-if="Object.keys(featuredAssets).length > 0"
            class="home-slider__wrapper"
            :class-name="'featured'"
            :slides="featuredAssets"
            :title="'Featured Games'"
            :path="'featured'"
            :query="{
                featured: true,
                offset: 0,
                limit: 30,
                include_tags: true,
                platform_compatibility: mobile || iOS ? 1 : 0,
            }"
            @show-all="onShowAll"
        />
        <HomeSlider
            v-if="Object.keys(popularAssets).length > 0"
            class="home-slider__wrapper"
            :class-name="'popular'"
            :slides="popularAssets"
            :title="'Popular Games'"
            :path="'popular'"
            :query="{
                order_fields: ['subscribers_count'],
                order_direction: ['desc'],
                offset: 0,
                limit: 30,
                include_tags: true,
                platform_compatibility: mobile || iOS ? 1 : 0,
            }"
            @show-all="onShowAll"
        />
        <HomeSlider
            v-if="Object.keys(newAssets).length > 0"
            class="home-slider__wrapper"
            :class-name="'new'"
            :slides="newAssets"
            :title="'New Games'"
            :path="'new'"
            :query="{
                order_fields: ['released_at'],
                order_direction: ['desc'],
                offset: 0,
                limit: 30,
                include_tags: true,
                platform_compatibility: mobile || iOS ? 1 : 0,
            }"
            @show-all="onShowAll"
        />
    </div>
</template>

<script>
import HomeSlider from '@/components/common/sliders/HomeSlider.vue';
import { mapGetters } from 'vuex';

export default {
    components: { HomeSlider },
    props: {
        featuredAssets: { type: Array, default: () => [] },
        newAssets: { type: Array, default: () => [] },
        popularAssets: { type: Array, default: () => [] },
    },
    computed: {
        ...mapGetters('settings', {
            mobile: 'isMobile',
            iOS: 'isIOS',
        }),
    },
    methods: {
        onShowAll() {
            this.$emit('show-all', event);
        },
    },
};
</script>

<style lang="scss" scoped>
.home-slider__wrapper {
    @media (max-width: 480px) {
        margin-bottom: 40px;
    }
}
</style>
