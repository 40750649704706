var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(Object.keys(_vm.featuredAssets).length > 0)?_c('HomeSlider',{staticClass:"home-slider__wrapper",attrs:{"class-name":'featured',"slides":_vm.featuredAssets,"title":'Featured Games',"path":'featured',"query":{
            featured: true,
            offset: 0,
            limit: 30,
            include_tags: true,
            platform_compatibility: _vm.mobile || _vm.iOS ? 1 : 0,
        }},on:{"show-all":_vm.onShowAll}}):_vm._e(),(Object.keys(_vm.popularAssets).length > 0)?_c('HomeSlider',{staticClass:"home-slider__wrapper",attrs:{"class-name":'popular',"slides":_vm.popularAssets,"title":'Popular Games',"path":'popular',"query":{
            order_fields: ['subscribers_count'],
            order_direction: ['desc'],
            offset: 0,
            limit: 30,
            include_tags: true,
            platform_compatibility: _vm.mobile || _vm.iOS ? 1 : 0,
        }},on:{"show-all":_vm.onShowAll}}):_vm._e(),(Object.keys(_vm.newAssets).length > 0)?_c('HomeSlider',{staticClass:"home-slider__wrapper",attrs:{"class-name":'new',"slides":_vm.newAssets,"title":'New Games',"path":'new',"query":{
            order_fields: ['released_at'],
            order_direction: ['desc'],
            offset: 0,
            limit: 30,
            include_tags: true,
            platform_compatibility: _vm.mobile || _vm.iOS ? 1 : 0,
        }},on:{"show-all":_vm.onShowAll}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }